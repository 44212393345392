import React from "react"
import Layout from "../modules/layout"
import Seo from "../components/Seo"

const Page404 = () => (
  <Layout>
    <Seo 
      title="404: Not found" 
    />
    <h3>Sorry I couldn't find what you were looking for...</h3>
  </Layout>
)

export default Page404